import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-common';

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '1767a0ab-772c-46e0-9edb-ce6b258da8a5',
    isCookieUseDisabled: true,
    isStorageUseDisabled: true,
    enableSessionStorageBuffer: false,
  },
});

export const initialize = (): void => {
  appInsights.loadAppInsights();
};

export const trackPage = (pageView: IPageViewTelemetry): void => {
  appInsights.trackPageView(pageView);
};
