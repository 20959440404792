/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { initialize, trackPage } from './src/TelemetryTracking';

export const onClientEntry = () => {
  initialize();
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const pushTrackPage = () => {
    // delay so that the title is captured
    trackPage({
      uri: location,
      refUri: prevLocation,
    });
  };

  setTimeout(pushTrackPage, 2000);
};
